@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');

.text-outlined {
	-webkit-text-stroke: 1px #fff;
}

body {
	font-family: "Open Sans", sans-serif;
	font-optical-sizing: auto;
	@apply bg-gray-900;
}
